var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e210d8ca4edd213b763ce44239a595058cc1e3c0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as FullStory from "@fullstory/browser"
import SentryFullStory from "@sentry/fullstory"
import { httpClientIntegration } from "@sentry/integrations"
import * as Sentry from "@sentry/nextjs"

import { APP_ENVIRONMENT, AppEnvironments } from "./utils/environment"

if (APP_ENVIRONMENT !== AppEnvironments.local) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: APP_ENVIRONMENT,
    integrations: [httpClientIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    tracesSampleRate: 0.01,
  })

  FullStory.init({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID }, () => {
    Sentry.getClient()?.addIntegration?.(
      new SentryFullStory(process.env.SENTRY_ORG, { client: FullStory })
    )
  })
}
